// src/OrderTable.jsx
import React, { useState } from 'react';
import PackagingChecklist from './PackagingChecklist';
const OrderTable = ({ orders, markOrderReady }) => {
  const [openRow, setOpenRow] = useState(null);

  const toggleRow = (invoiceNumber) => {
    setOpenRow(openRow === invoiceNumber ? null : invoiceNumber);
  };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border border-gray-200">
        <thead className="font-bold bg-gt-white">
          <tr className='text-gt-teal font-bold'>
            <th className="px-6 py-3 border-b border-gray-200 bg-gt-white text-left text-xs uppercase">
              Zomato/Swiggy Order Number
            </th>
            <th className="px-6 py-3 border-b border-gray-200 bg-gt-white text-left text-xs uppercase tracking-wider">
              Channel
            </th>
            <th className="px-6 py-3 border-b border-gray-200 bg-gt-white text-left text-xs uppercase tracking-wider">
              Status
            </th>
          </tr>
        </thead>
        <tbody className="text-left divide-y divide-gray-200">
          {orders.map((order) => (
            <React.Fragment key={order.invoiceNumber}>
              <tr className="hover:bg-gray-100 cursor-pointer" onClick={() => toggleRow(order.invoiceNumber)}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                  #{order.sourceInfo && order.sourceInfo.invoiceNumber.slice(-4)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                  {order.channel}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700">
                  {order.fulfillmentStatus}
                </td>
              </tr>
              {openRow === order.invoiceNumber && (
                <tr>
                  <td colSpan="8" className="px-6 py-4 bg-gray-50">
                    <div className="overflow-x-auto">
                      <table className="min-w-full bg-white">
                        <thead>
                          <tr>
                            <th className="px-4 py-2 border">Item Name</th>
                            <th className="px-4 py-2 border">Size</th>
                            <th className="px-4 py-2 border">Quantity</th>
                          </tr>
                        </thead>
                        <tbody>
                          {order.items.map((item, index) => (
                            <tr key={index} className="hover:bg-gray-100">
                              <td className="px-4 py-2 border">{item.longName}</td>
                              <td className="px-4 py-2 border">{item.variants || "N/A"}</td>
                              <td className="px-4 py-2 border">{item.quantity}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              )}
              {openRow === order.invoiceNumber && (
                <tr>
                    <td colSpan="8" className="px-6 py-4 bg-gray-50">
                    <PackagingChecklist order={order} markOrderReady={markOrderReady}/>
                    </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderTable;