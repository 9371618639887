import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import './Loader.css';
function Loader(props){
    return <div className={'loaderContainer'}>
        <FontAwesomeIcon  spin={true} icon={faSpinner} size='2x'/>
        </div>
}

export default Loader;