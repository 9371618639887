import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
const moment = require('moment');
const { detect } = require('detect-browser');
const browser = detect();
const Utilities  = 
{
    formatAsPercent:(val)=>{
        return `${(val * 100.0).toFixed(0)}%`;
    },
    formatName : (text)=>{
        if(Utilities.isNullOrEmpty(text)){
            return 'Unknown';
        }
        return text.replace(
            /\w\S*/g,
            function(txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
          );
    },
    extractInfo : (userInfo)=>{
        let message = `${userInfo.userName} (+91${userInfo.phone}) `;
        if(userInfo.address){
            message += `${userInfo.address.address1 || ''} ${userInfo.address.address2 || ''} ${userInfo.address.googleAddress || ''}`;
            message += ` https://maps.google.com/?q=${userInfo.address.latitude},${userInfo.address.longitude}&z=8`
        }
        return message;
    },
    isCombo : (productName)=>{
        return productName.trim() === "1 Bubble Tea + 1 Croffle" || productName.trim() === "Milk Tea + Croffle Combo" || productName.trim() === "Refresher + Croffle Combo";
    },
    isCustomization : (itemName)=>{
        return  itemName.toLowerCase() === ("no") || itemName.toLowerCase() === ("yes") || itemName.toLowerCase() === ("regular") || itemName.toLowerCase() === ("regular milk") || itemName.toLowerCase() === ("almond milk") || itemName.toLowerCase().includes("low") || itemName.toLowerCase().includes("sweet") || itemName.toLowerCase() === ("hot") || itemName.toLowerCase() === ("cold") || itemName.toLowerCase() === ("coconut jelly") || itemName.toLowerCase().includes("add milk foam") || itemName.toLowerCase().includes("shot") || itemName.toLowerCase() === ("extra bubbles");
    },
    isSnack : (productName)=>{
        return (productName.toLowerCase().includes("puff") || productName.toLowerCase().includes("dacquoise") || productName.toLowerCase().includes("rollcake") || productName.toLowerCase().includes("croffle") || productName.toLowerCase().includes("scone") || productName.toLowerCase().includes("sandwich") || productName.toLowerCase().includes("chicken"))
    },
    isMerch : (productName)=>{
        return (productName.toLowerCase().includes("got") || productName.toLowerCase().includes("tumbler") || productName.toLowerCase().includes("tapioca") || productName.toLowerCase().includes("reusable") || productName.toLowerCase().includes("kit") || productName.toLowerCase().includes("boba mat"));
    },
    formatDate : (date)=>{
        return browser.name.toLowerCase() === 'chrome' ? moment(new Date(date)).format('MMMM Do YYYY, h:mm:ss a') : date;
    },
    formatIndiaTime : (date)=>{
        return browser.name.toLowerCase() === 'chrome' ? moment(new Date(date)).format('DD-MM-YYYY, h:mm:ss a') : date;
    },
    getCurrentBrowser :()=>{
        return browser.name || '';
    },
    setToken : () => {
        localStorage.setItem("accessToken", "a82dff44e3b0733dec84339e319c7f00");
    },
    checkLoggedIn : ()=>{
        return localStorage.getItem("accessToken") !== null;
    },
    logout : ()=>{
        localStorage.removeItem("accessToken");
        firebase.auth().signOut();
        window.location.href = '/login';
    },
    goHome: ()=>{
        window.location.href = '/';
    },
    goToCustomers : ()=>{
        window.location.href = '/customers';
    },
    isNullOrEmpty: (item) => {
        if (typeof item === 'undefined' || (item === null)) {
            return true;
        }
        if (typeof item === 'string') {
            return item.trim() === '';
        }
        else if (typeof item === 'object') {
            return Object.keys(item).length === 0;
        }
        else if (typeof item === 'number') {
            return item <= 0;
        }
        return false;
    },
    getTotal : (stats)=>{
        let total = 0;
        for(let item of stats){
            if(item && item.count){
                total += item.count;
            }
        }
        return total;
    },
    validateEmail: (email) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,20})+$/.test(email)) {
            return true;
        }
        return false;
    },
    formatIndianCurrency:(x)=> {
        return x.toFixed(2).split('.')[0].length > 3 ? x.toFixed(2).substring(0,x.toFixed(2).split('.')[0].length-3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toFixed(2).substring(x.toFixed(2).split('.')[0].length-3): x.toFixed(2);
    },
    makeWebRequest: (url, method, body) => {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        if(body && body.headers){
            for(let key  in  body.headers){
                myHeaders.append(key, body.headers[key]);
            }
            delete body.headers;
        }
        
        var requestOptions = {
            method: method,
            headers: myHeaders,
            redirect: 'follow'
        };

        if (body && method.toLowerCase() !==  "get") {
            var raw = JSON.stringify(body);
            requestOptions.body = raw;
        }

        return new Promise(function (resolve, reject) {
            fetch(url, requestOptions)
                .then(response => response.text())
                .then(result => {
                    let json = JSON.parse(result);
                    if (json.success) {
                        resolve(json.data);
                    } else if(json.status === 200){
                        if(json.result.data){
                            resolve(json.result.data);
                        } else {
                            resolve(json.result);
                        }
                    } else {
                        reject(json.error);
                    }
                }).catch((error) => {
                    reject(error);
                });
        });
    }
}
export default Utilities;